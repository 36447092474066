@import '_screen_width';
@import '_variables';
@import '_reset';
@import '_fonts';
@import '_select';
@import '_table';

.debug * {
    box-shadow: inset 0 0 0 1px red;
}

.error {
    border-color: var(--errorColor) !important;
    &::placeholder {
        color: var(--errorColor);
    }
}
.warning {
    border-color: var(--warningColor) !important;
}
.info {
    border-color: var(--infoColor) !important;
}
.success {
    border-color: var(--successColor) !important;
}

* {
    box-sizing: border-box;
}

html,
body {
    width: 100%;
    min-width: $minWidth;
    height: 100%;
}

html {
    font-size: var(--baseFontSize);
    font-style: normal;
    font-weight: 500;
    line-height: var(--baseLineHeight);
    color: var(--textColor);
    background-color: var(--lightGrey);
    * {
        font-family: var(--font);
    }
}

::selection {
    background: rgba(var(--mainColor-rgb), 0.7);
    color: var(--white);
}

::-moz-selection {
    background: rgba(var(--mainColor-rgb), 0.7);
    color: var(--white);
}

b,
strong,
.bold {
    font-weight: 700;
}

i,
em {
    font-style: italic;
}

s,
strike,
del {
    text-decoration: line-through;
}

u,
ins {
    text-decoration: underline;
}

small,
sub,
sup,
.small {
    font-size: 0.846rem;
    line-height: var(--baseLineHeight);
}
.big {
    font-size: 1.154rem;
    line-height: var(--baseLineHeight);
}
sub,
sup {
    vertical-align: sub;
}
sup {
    vertical-align: super;
}

mark {
    background-color: yellow;
    color: black;
}

img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}
picture img {
    width: 100%;
}

@for $i from 1 through 6 {
    h#{$i},
    .h#{$i} {
        $diff: #{($i - 1) * 0.1}rem;
        font-size: calc(var(--headerBaseFontSize) - $diff);
        line-height: var(--baseLineHeight);
        font-weight: 700;
        color: var(--headersColor);
        @if $i < 4 {
            margin: var(--gap) auto calc(var(--gap) * 2);
        } @else {
            margin: var(--gap) auto;
        }
        &:first-child {
            margin-top: 0;
        }
    }
}

p {
    margin: var(--gap) 0;
}

.gap {
    margin: var(--gap) 0;
    &-top {
        margin-top: var(--gap);
    }
    &-bottom {
        margin-bottom: var(--gap);
    }
}
.nogap {
    p {
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.relative {
    position: relative;
}
.center {
    text-align: center;
}
.right {
    text-align: right;
}
.left {
    text-align: left;
}
.nowrap {
    white-space: nowrap;
}
.secondary {
    color: var(--darkGrey);
}
.text {
    &-black {
        color: var(--black);
        &:hover {
            color: var(--black);
        }
    }
    &-red {
        color: var(--red);
    }
}
.wide {
    width: 100%;
}
.section {
    margin: calc(var(--gap) * 2) auto;

    &-bordered {
        @extend .section;
        padding: var(--fieldsGap);
        border: 2px dashed var(--mediumGrey);
    }
}

ul,
ol {
    margin: 1rem;
}

ol {
    list-style: decimal;
}

li {
    margin: 0.3rem 0 0.3rem 1rem;
    padding: 0 0 0 0.7rem;
}

ul li {
    list-style-type: disc;
}

button {
    font-weight: 500;
    white-space: nowrap;
}

a,
input[type='button'],
input[type='submit'],
input[type='reset'] {
    cursor: pointer;
}

a {
    text-decoration: underline;
    color: var(--linkColor);
    transition: color 0.3s ease;
    cursor: pointer;
    &:hover {
        text-decoration: none;
        color: var(--hoverColor);
    }
}

input,
textarea {
    display: block;
    width: 100%;
    margin: 0;
    padding: calc(var(--inputPadding) * 0.77) calc(var(--inputPadding) * 1.08);
    border: 2px solid var(--grey);
    font-weight: 500;
    color: var(--textColor);
    background-color: var(--white);
    border-radius: var(--inputBorderRadius);
    &.dark {
        border-color: var(--textColor);
        color: var(--textColor);
    }
    &.white {
        border-color: var(--white);
        color: var(--white);
    }
    &.transparent {
        border-color: transparent;
    }
    &[disabled],
    &[readonly] {
        background-color: rgba(var(--lightGrey-rgb), 0.7) !important;
    }
    &[disabled] {
        cursor: not-allowed;
    }
    &[readonly] {
        cursor: default;
    }
    &.high {
        padding: calc(var(--buttonPadding) * 1.04) calc(var(--buttonPadding) * 1.69);
        font-size: 1.15rem;
        line-height: 1;
    }
}

textarea {
    max-width: 100%;
    height: 7rem;
    border-radius: var(--textareaBorderRadius);
    &.high {
        line-height: var(--baseLineHeight);
    }
}

input {
    &[type='button'],
    &[type='submit'],
    &[type='reset'] {
        @extend .btn;
    }

    &[type='radio'],
    &[type='checkbox'] {
        position: absolute;
        width: 0;
        height: 0;
        line-height: 0;
        opacity: 0;
        visibility: hidden;
        z-index: -1;

        + label {
            position: relative;
            display: inline-block;
            height: 24px;
            padding: 0 0 0 29px;
            line-height: 24px;
            cursor: pointer;
            vertical-align: top;
            &:after,
            &:before {
                content: '';
                position: absolute;
                display: block;
            }
            &:after {
                top: 3px;
                left: 0;
                width: 14px;
                height: 14px;
                border: 2px solid var(--mainColor);
                z-index: 1;
            }
            &:before {
                top: 7px;
                left: 4px;
                width: 10px;
                height: 10px;
            }
            &:before {
                background-color: var(--mainColor);
                z-index: 2;
                opacity: 0;
                transform: scale(0.1);
                transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
            &:hover {
                &:before {
                    opacity: 0.7;
                    transform: scale(0.7);
                }
            }
            &:active {
                &:before {
                    opacity: 0;
                    transform: scale(0);
                }
            }
        }
        &:checked {
            + label {
                &:before {
                    opacity: 1;
                    transform: scale(1);
                }
                &:active {
                    &:before {
                        transform: scale(0);
                    }
                }
            }
        }
        &[disabled],
        &[readonly] {
            + label {
                color: var(--mediumGrey) !important;
                &:after {
                    border-color: var(--mediumGrey) !important;
                }
                &:before {
                    background-color: var(--mediumGrey) !important;
                }
            }
        }
        &[disabled] {
            + label {
                cursor: not-allowed;
            }
        }
        &[readonly] {
            + label {
                cursor: default;
            }
        }
    }
    &[type='radio'] {
        + label {
            &:after,
            &:before {
                border-radius: 50%;
            }
        }
    }
    &[type='checkbox'] {
        + label {
            &:after,
            &:before {
                border-radius: 2px;
            }
        }
    }
    &[type='file'] {
        display: none;
        padding-left: 0;
        padding-right: 0;
        border: none;
    }
}

.btn {
    @extend .small;
    display: block;
    width: 100%;
    margin: 0;
    padding: calc(var(--buttonPadding) * 1.05) calc(var(--buttonPadding) * 1.83);
    border: 0 solid var(--mainColor);
    font-weight: 700;
    letter-spacing: 10%;
    line-height: 1rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    background-color: var(--mainColor);
    cursor: pointer;
    border-radius: var(--buttonBorderRadius);
    box-sizing: border-box;
    transform-origin: 50% 90%;
    color: var(--buttonTextColor);
    box-shadow:
        0 0 0 0 rgba(0, 0, 0, 0),
        inset 0 0 0 rgba(0, 0, 0, 0);
    transition:
        transform 0.3s ease,
        box-shadow 0.3s ease;
    &:hover,
    &:focus {
        color: var(--buttonTextColor);
        box-shadow:
            0 8px 5px -5px rgba(0, 0, 0, 0.3),
            inset 0 0 0 rgba(0, 0, 0, 0);
        transform: scale(1.015);
    }
    &:active {
        color: var(--buttonTextColor);
        box-shadow:
            0 0 5px -5px rgba(0, 0, 0, 0.3),
            inset 0 1px 5px rgba(0, 0, 0, 0.3);
        transform: scale(0.98);
    }
    &-high {
        padding-top: calc(var(--buttonPadding) * 1.42);
        padding-bottom: calc(var(--buttonPadding) * 1.42);
    }
    &-outline {
        padding: calc(var(--buttonPadding) * 0.88) calc(var(--buttonPadding) * 2);
        border-width: 2px;
        color: var(--mainColor);
        background-color: var(--white);
        &.btn-high {
            padding-top: calc(var(--buttonPadding) * 1.27);
            padding-bottom: calc(var(--buttonPadding) * 1.27);
        }
        &:active {
            border-color: var(--darkMainColor);
        }
    }
    &-narrow {
        width: 200px;
    }
    &-auto {
        width: auto;
        padding-left: calc(var(--gap) * 2);
        padding-right: calc(var(--gap) * 2);
    }
    &-red {
        background-color: var(--red);
    }
}

.loader {
    color: transparent !important;
    background-color: var(--mainColor);
    background-image: url('/assets/images/stripe.svg');
    background-repeat: repeat;
    animation: 0.9s linear infinite loader;
    box-shadow: none !important;
    transform: none !important;
    &.grey {
        background-color: var(--lightGrey);
    }
}
@keyframes loader {
    0% {
        background-position: 0 top;
    }
    100% {
        background-position: 89px top;
    }
}

.ng-touched.ng-invalid,
.ng-submitted .ng-invalid {
    @extend .error;
}

.toast-container {
    position: fixed;
    top: 12px;
    right: 12px;
    max-width: 400px;
    pointer-events: none;
    z-index: 999999;
}

.toast-error,
.toast-warning,
.toast-info,
.toast-success {
    padding: 20px;
    margin-bottom: 12px;
    color: var(--white);
    border-radius: 10px;
}

.toast-error {
    background-color: rgba(var(--errorColor-rgb), 0.9);
    box-shadow:
        0 0 10px var(--errorColor),
        inset 0 0 1px rgba(0, 0, 0, 0.3);
}
.toast-warning {
    background-color: rgba(var(--warningColor-rgb), 0.9);
    box-shadow:
        0 0 10px var(--warningColor),
        inset 0 0 1px rgba(0, 0, 0, 0.3);
}
.toast-info {
    background-color: rgba(var(--infoColor-rgb), 0.9);
    box-shadow:
        0 0 10px var(--infoColor),
        inset 0 0 1px rgba(0, 0, 0, 0.3);
}
.toast-success {
    background-color: rgba(var(--successColor-rgb), 0.9);
    box-shadow:
        0 0 10px var(--successColor),
        inset 0 0 1px rgba(0, 0, 0, 0.3);
}
