// ng-select styles
@import "@ng-select/ng-select/themes/default.theme.css";

.custom.ng-select {
    display: block;
    width: 100%;
    margin: 0;
    input {
        margin: 0;
        border-radius: 0;
    }
    .ng-select-container {
        width: 100%;
        margin: 0;
        border: 2px solid var(--grey);
        color: var(--textColor);
        background-color: var(--white);
        border-radius: var(--inputBorderRadius);
        .ng-value-container {
            align-items: flex-start;
            min-height: var(--baseLineHeight);
            height: auto;
            padding: calc(var(--inputPadding) * 0.7)
                calc(var(--inputPadding) * 0.4) calc(var(--inputPadding) * 0.7)
                calc(var(--inputPadding) * 1.08);
            box-sizing: content-box;
            .ng-placeholder {
                font-size: 100%;
                line-height: normal;
            }
        }
    }
    &.ng-select-single,
    &.ng-select-multiple {
        .ng-select-container {
            min-height: 1rem;
            height: auto;
            .ng-value-container {
                .ng-value {
                    line-height: normal;
                }
            }
        }
    }
    &.ng-select-single {
        .ng-select-container {
            .ng-value-container {
                .ng-input {
                    top: 0;
                    margin-top: 0;
                    padding: calc(var(--inputPadding) * 0.7)
                        calc(var(--inputPadding) * 1.08);
                }
            }
        }
    }
    &.ng-select-multiple {
        .ng-select-container {
            height: auto;
            .ng-value-container {
                padding: calc(var(--inputPadding) * 0.7)
                    calc(var(--inputPadding) * 1.08);
                .ng-input {
                    padding: 0;
                }
                .ng-placeholder {
                    padding: calc(var(--inputPadding) * 0.3) 0 0;
                }
                .ng-value {
                    height: var(--baseLineHeight);
                    margin-bottom: 0.6rem;
                    margin-right: 0.6rem;
                    font-size: 1rem;
                    line-height: var(--baseLineHeight);
                    color: var(--textColor);
                    background-color: var(--lightGrey);
                    .ng-value-icon,
                    .ng-value-label {
                        height: var(--baseLineHeight);
                        padding: 0 5px;
                    }
                    .ng-value-icon {
                        &:hover {
                            background-color: var(--grey);
                        }
                        &.left {
                            border-right: 1px solid var(--mediumGrey);
                        }
                        &.right {
                            border-left: 1px solid var(--mediumGrey);
                        }
                    }
                }
            }
            &.ng-has-value {
                .ng-value-container {
                    padding: calc(var(--inputPadding) * 0.6)
                        calc(var(--inputPadding) * 1.5)
                        calc(var(--inputPadding) * 0.2);
                }
            }
        }
    }
    &.ng-select-focused {
        &:not(.ng-select-opened) > .ng-select-container {
            border-color: var(--grey);
            box-shadow: none;
        }
    }
    &.ng-select-opened {
        &.ng-select-top > .ng-select-container {
            border-top-right-radius: var(--inputBorderRadius);
            border-top-left-radius: var(--inputBorderRadius);
        }
        &.ng-select-bottom > .ng-select-container {
            border-bottom-right-radius: var(--inputBorderRadius);
            border-bottom-left-radius: var(--inputBorderRadius);
        }
        > .ng-select-container {
            border-color: var(--grey);
            .ng-arrow {
                top: 50%;
                border: none;
                transform: rotate(180deg);
            }
        }
    }
    .ng-clear-wrapper,
    .ng-arrow-wrapper {
        width: auto;
        .ng-clear,
        .ng-arrow {
            display: block;
            width: var(--iconSize);
            height: var(--iconSize);
            border: none;
            background-position: 0 0;
            background-repeat: no-repeat;
        }
    }
    .ng-clear-wrapper {
        .ng-clear {
            font-size: 0;
            line-height: 0;
            background-image: url("/assets/images/icons/close_grey.svg");
        }
    }
    .ng-arrow-wrapper {
        padding-right: calc(var(--inputPadding) * 1.08);
        .ng-arrow {
            background-image: url("/assets/images/icons/down_black.svg");
        }
    }
}

.ng-dropdown-panel {
    border: 2px solid var(--grey);
    overflow: hidden;
    &.ng-select-top {
        bottom: 100%;
        margin-bottom: -2px;
        border-bottom-color: var(--grey);
        border-radius: var(--inputBorderRadius);
    }
    &.ng-select-bottom {
        top: 100%;
        margin-top: -2px;
        border-top-color: var(--grey);
        border-radius: var(--inputBorderRadius);
    }
    .ng-dropdown-panel-items {
        padding: 0 calc(var(--inputPadding) * 1.08);
        .ng-option {
            position: relative;
            padding: calc(var(--inputPadding) * 0.96) 0;
            &.ng-option-disabled {
                padding: calc(var(--inputPadding) * 0.7)
                    calc(var(--inputPadding) * 1.08);
            }
            &.ng-option-selected,
            &.ng-option.ng-option-marked,
            &.ng-option-selected.ng-option-marked {
                background-color: transparent;
                color: var(--textColor);
            }
            &.ng-option-selected {
                position: relative;
                padding-right: var(--iconSize);
                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    right: 0;
                    display: block;
                    width: var(--iconSize);
                    height: var(--iconSize);
                    margin-top: calc(var(--iconSize) * -0.5);
                    background-image: url("/assets/images/icons/check_blue.svg");
                    background-position: 0 0;
                    background-repeat: no-repeat;
                }
            }
            & ~ .ng-option {
                border-top: 1px solid var(--grey);
            }
        }
    }
}
