@font-face {
    font-display: swap;
    font-family: "Montserrat";
    src: url("/assets/fonts/montserrat/eot/Montserrat-Light.eot");
    src: local("Montserrat Light"), local("Montserrat-Light"),
        url("/assets/fonts/montserrat/eot/Montserrat-Light.eot?#iefix")
            format("embedded-opentype"),
        url("/assets/fonts/montserrat/woff2/Montserrat-Light.woff2")
            format("woff2"),
        url("/assets/fonts/montserrat/woff/Montserrat-Light.woff")
            format("woff"),
        url("/assets/fonts/montserrat/ttf/Montserrat-Light.ttf")
            format("truetype"),
        url("/assets/fonts/montserrat/svg/Montserrat-Light.svg#Montserrat")
            format("svg");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: "Montserrat";
    src: url("/assets/fonts/montserrat/eot/Montserrat-SemiBold.eot");
    src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"),
        url("/assets/fonts/montserrat/eot/Montserrat-SemiBold.eot?#iefix")
            format("embedded-opentype"),
        url("/assets/fonts/montserrat/woff2/Montserrat-SemiBold.woff2")
            format("woff2"),
        url("/assets/fonts/montserrat/woff/Montserrat-SemiBold.woff")
            format("woff"),
        url("/assets/fonts/montserrat/ttf/Montserrat-SemiBold.ttf")
            format("truetype"),
        url("/assets/fonts/montserrat/svg/Montserrat-SemiBold.svg#Montserrat")
            format("svg");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: "Montserrat";
    src: url("/assets/fonts/montserrat/eot/Montserrat-Bold.eot");
    src: local("Montserrat Bold"), local("Montserrat-Bold"),
        url("/assets/fonts/montserrat/eot/Montserrat-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("/assets/fonts/montserrat/woff2/Montserrat-Bold.woff2")
            format("woff2"),
        url("/assets/fonts/montserrat/woff/Montserrat-Bold.woff") format("woff"),
        url("/assets/fonts/montserrat/ttf/Montserrat-Bold.ttf")
            format("truetype"),
        url("/assets/fonts/montserrat/svg/Montserrat-Bold.svg#Montserrat")
            format("svg");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: "Montserrat";
    src: url("/assets/fonts/montserrat/eot/Montserrat-Regular.eot");
    src: local("Montserrat Regular"), local("Montserrat-Regular"),
        url("/assets/fonts/montserrat/eot/Montserrat-Regular.eot?#iefix")
            format("embedded-opentype"),
        url("/assets/fonts/montserrat/woff2/Montserrat-Regular.woff2")
            format("woff2"),
        url("/assets/fonts/montserrat/woff/Montserrat-Regular.woff")
            format("woff"),
        url("/assets/fonts/montserrat/ttf/Montserrat-Regular.ttf")
            format("truetype"),
        url("/assets/fonts/montserrat/svg/Montserrat-Regular.svg#Montserrat")
            format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: "Montserrat";
    src: url("/assets/fonts/montserrat/eot/Montserrat-Medium.eot");
    src: local("Montserrat Medium"), local("Montserrat-Medium"),
        url("/assets/fonts/montserrat/eot/Montserrat-Medium.eot?#iefix")
            format("embedded-opentype"),
        url("/assets/fonts/montserrat/woff2/Montserrat-Medium.woff2")
            format("woff2"),
        url("/assets/fonts/montserrat/woff/Montserrat-Medium.woff")
            format("woff"),
        url("/assets/fonts/montserrat/ttf/Montserrat-Medium.ttf")
            format("truetype"),
        url("/assets/fonts/montserrat/svg/Montserrat-Medium.svg#Montserrat")
            format("svg");
    font-weight: 500;
    font-style: normal;
}
