$oneMoreGrey: #e0e0e0;
$rowGap: 6px;
$baseRowBorderRadius: 5px;
$rowBorderRadius: $baseRowBorderRadius + ($rowGap * 0.5);

table.data-table {
    width: calc(100% + $rowGap);
    margin: calc(var(--gap) * 2) ($rowGap * -0.5);
    border-collapse: separate;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border-radius: $rowBorderRadius;
    background-color: $oneMoreGrey;
    & > tr > th,
    & > thead > tr > th,
    & > thead > tr > td {
        background-color: var(--lightGrey);
        color: var(--darkGrey);
    }

    & > tr > td,
    & > tbody > tr > td {
        background-color: var(--white);
    }

    & > tr,
    & > thead > tr,
    & > tbody > tr {
        & > th,
        & > td {
            padding: var(--gap);
            border: ($rowGap * 0.5) solid $oneMoreGrey;
            border-left: none;
            border-right: none;
            vertical-align: top;
        }
        & > th,
        & > td {
            &:first-child {
                border: ($rowGap * 0.5) solid $oneMoreGrey;
                border-right: none;
                border-radius: $rowBorderRadius 0 0 $rowBorderRadius;
            }
        }
        & > th,
        & > td {
            &:last-child {
                border: ($rowGap * 0.5) solid $oneMoreGrey;
                border-left: none;
                border-radius: 0 $rowBorderRadius $rowBorderRadius 0;
            }
        }

        &.section-header {
            user-select: none;
            & > td {
                position: relative;
                border: ($rowGap * 0.5) solid $oneMoreGrey;
                font-weight: bold;
                color: var(--white);
                background-color: var(--mainColor);
                border-radius: $rowBorderRadius;
            }
            &.toogle {
                & > td {
                    background-color: var(--lightMainColor);
                    cursor: pointer;
                    &::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: 15px;
                        display: block;
                        margin-top: -9px;
                        width: 18px;
                        height: 18px;
                        background-image: url('/assets/images/arrow-circle-down_white.svg');
                        background-size: 100% 100%;
                    }
                }
                &.open {
                    & > td {
                        background-color: var(--mainColor);
                        &::after {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }
}
