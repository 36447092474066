:root {
    // Шрифт
    --font: 'Montserrat', sans-serif;
    --baseFontSize: 13px;
    --baseLineHeight: 1.4em;
    --headerBaseFontSize: 1.5rem;

    // Размер иконок
    --iconSize: 1.85rem;

    // Отступ
    --gap: 16px;

    // Параметры полей
    --inputBorderRadius: 2rem;
    --textareaBorderRadius: 5px;
    --buttonBorderRadius: 2rem;
    --inputPadding: 1rem;
    --buttonPadding: 1rem;
    --fieldsGap: calc(var(--gap) * 2);

    // Глобальные цвета
    --white: #ffffff;
    --black: #000000;
    --red: #f63f3f;
    --darkRed: #d32525;
    --lightRed: var(--darkPink);
    --pink: #fa8c8c;
    --darkPink: #ff6868;
    --lightPink: #ffc7c7;
    --grey: #dadada;
    --darkGrey: #707070;
    --mediumGrey: #bbbbbb;
    --lightGrey: #f6f6f6;
    --blue: #4189ec;
    --darkBlue: #1462cc;
    --lightBlue: #6aa5f5;
    --yellow: #fff5c1;
    --darkYellow: #cfa147;
    --mediumYellow: #efcb69;
    --green: #88ab3f;
    --darkGreen: #5c732a;
    --lightGreen: #b0d267;
    --violet: #4d41db;
    --darkViolet: #3329a7;
    --lightViolet: #6559ef;

    --errorColor: var(--red);
    --warningColor: var(--darkYellow);
    --infoColor: var(--lightBlue);
    --successColor: var(--green);

    --textColor: var(--black);
    --buttonTextColor: var(--white);
    --headersColor: var(--black);
    --linkColor: var(--mainColor);
    --hoverColor: var(--darkMainColor);

    // rgb палитра, нужны при использования rgba
    --grey-rgb: 218, 218, 218;
    --lightGrey-rgb: 246, 246, 246;
    --errorColor-rgb: 246, 63, 63;
    --warningColor-rgb: 218, 183, 88;
    --infoColor-rgb: 106, 165, 245;
    --successColor-rgb: 136, 171, 63;
}
